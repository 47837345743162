<template>
  <div class="text-dark-blue login">
    <router-link to="/" class="text-dark-blue">
      <h1 class="mb-5 text-dark-blue">{{ $t("documentManagementSystem") }}</h1>
    </router-link>
    <div class="login-form">
      <Alert :alert="alert" />
      <b-form @submit.prevent="loginUser" class="form-style">
        <b-form-group class="password-icon">
          <label for="email">{{ $t("emailAddress") }}</label>
          <b-form-input
            type="email"
            inputmode="email"
            id="email"
            v-model="$v.form.email.$model"
            :state="!$v.form.email.$error"
          />
          <b-form-invalid-feedback v-if="!$v.form.email.required">{{
            $t("validateMessages.pleaseEmail")
          }}</b-form-invalid-feedback>
          <b-form-invalid-feedback v-if="!$v.form.email.email">{{
            $t("validateMessages.pleaseEmailFormat")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group class="password-icon">
          <label for="password">{{ $t("password") }}</label>
          <b-form-input
            :type="!passEye ? 'password' : 'text'"
            id="password"
            v-model="$v.form.password.$model"
            :state="!$v.form.password.$error"
          />
          <span @click="passEye = !passEye">
            <font-awesome-icon
              :icon="passEye ? faEye : faEyeSlash"
              class="input-icon"
            />
          </span>
          <b-form-invalid-feedback v-if="!$v.form.password.required">{{
            $t("validateMessages.pleasePassword")
          }}</b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex justify-content-end forgot-password-route">
          <router-link to="/forgot-password" class="text-dark-blue">
            <p class="font-size-14">{{ $t("forgotPassword") }}</p>
          </router-link>
        </div>
        <button class="blue-button w-100 mt-3">
          <span v-if="!loading">{{ $t("login") }}</span>
          <b-spinner variant="light" v-else></b-spinner>
        </button>
        <p class="text-center mt-3">
          <router-link to="/registrieren" class="text-dark-blue">{{
            $t("register")
          }}</router-link>
        </p>
      </b-form>
      <router-link
        to="/"
        class="d-flex align-items-center router-hover justify-content-center mt-3"
      >
        <font-awesome-icon class="text-blue mr-2" :icon="faArrowLeft" />
        <p class="text-blue">{{ $t("returnHomePage") }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import Input from "../base/formElements/Input.vue";
import { login } from "../../services/Api";
import { validationMixin } from "vuelidate";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faEye,
  faEyeSlash,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import Alert from "../base/alert/Alert.vue";
import localStorageManager from "../../services/localStorageManager/index";
import { mapActions, mapGetters } from "vuex";
import { putUserInfoComeFromForm } from "../../services/modules/User";

const { required, email } = require("vuelidate/lib/validators");

export default {
  components: {
    Input,
    FontAwesomeIcon,
    Alert,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      faArrowLeft,
      faEye,
      alert: {
        show: false,
        variant: "",
        message: "",
      },
      loading: false,
      passEye: false,
      faEyeSlash,
    };
  },
  methods: {
    ...mapActions(["SET_ACTIVE_USER_ACTION", "SET_LOGOUT_ACTION"]),
    async loginUser() {
      this.$v.$touch();
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        const response = await login(this.form);

        if (response.message === "OK") {
          localStorageManager.userAuth.setUser(response.data);
          localStorageManager.userAuth.setUserToken(response.data.token);
          localStorageManager.userAuth.setLoginRoleType(response.data.role);
          this.SET_ACTIVE_USER_ACTION(response.data);

          if (!response.data.isActive) {
            this.$bvModal
              .msgBoxConfirm(this.$t("freezeMessage"), {
                title: "",
                size: "sm",
                buttonSize: "sm",
                okVariant: "danger",
                okTitle: this.$t("yes"),
                cancelTitle: this.$t("no"),
                footerClass: "p-2",
                hideHeaderClose: false,
                centered: true,
              })
              .then(async (value) => {
                if (value) {
                  const response = await putUserInfoComeFromForm(
                    this.activeUser.id,
                    { IsActive: true }
                  );

                  if (response.message === "OK") {
                    this.$router.push("/start");
                  }
                } else {
                  this.SET_LOGOUT_ACTION();
                }
              })
              .catch((err) => {
                // An error occurred
              });
          } else {
            console.log(this.getRedirectUrl);
            const url = this.getRedirectUrl?.fullPath || "/start";
            console.log("url: ", url);
            this.$router.push(url);
          }
        } else if (response.message === "NOT_FOUND") {
          this.alert.show = true;
          this.alert.variant = "danger";
          this.alert.message = "validateMessages.errorEmailPassword";
        }

        this.loading = false;
        setTimeout(() => {
          this.alert.show = false;
        }, 2000);
      }
    },
    checkAutoLogin() {
      if (this.getAutoLogin.status) {
        this.form = {
          email: this.getAutoLogin.email,
          password: this.getAutoLogin.password,
        };
        this.loginUser();
        this.$store.commit("CLEAR_AUTO_LOGIN");
      }
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["activeUser", "getAutoLogin", "getRedirectUrl"]),
  },
  mounted() {
    this.checkAutoLogin();
  },
};
</script>

<style lang="scss">
.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/image-3.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  .login-form {
    width: 100%;
    max-width: 500px !important;
    padding: 0 20px;
  }

  .forgot-password-route {
    p {
      text-decoration: underline;
    }
  }

  .password-icon {
    position: relative;

    .input-icon {
      position: absolute;
      top: 60%;
      right: 1.2rem;
      color: #01295f;
    }
  }

  .router-hover {
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
